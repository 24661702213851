@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  .break-auto-phrase {
    word-break: auto-phrase;
  }
  .custom-shadow {
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.25);
  }
  .custom-drop-shadow {
    @apply filter drop-shadow-md;
  }
  .line-clamp-1 {
    @apply overflow-hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-word;
  }
  .line-clamp-2 {
    @apply overflow-hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-word;
  }
  .youtube-embed-container > div {
    width: 100% !important;
    height: 100% !important;
  }
}
